import Footer from "@/components/elements/Footer";
import Navbar from "@/components/elements/Navbar";
import { useGetCurrentUrl } from "@/hooks";
import useAuth from "@/hooks/auth";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import PaymentSuccess from "../PaymentSuccess";
import Login from "../modal/Login";
import Register from "../modal/Register";
import Subscribe from "../modal/Subscribe";
import { useAtom } from "jotai";
import {
  subscribePopup,
  purchasePopup,
  purchaseStartupPopup,
  registerPopup,
  audioAtom,
  purchaseVCPopup,
  purchaseAngelInvestorPopup,
} from "@/Atoms";
import Purchase from "../modal/Purchase";
import PurchaseStartup from "../modal/PurchaseStartup";
import AudioPlayer from "@/components/audio/AudioPlayer";
import PurchaseVC from "../modal/PurchaseVC";
import PurchaseAngelInvestor from "../modal/PurchaseAngelInvestor";
const Layout = ({ children, title, meta_title, description, seoData, ...props }) => {
  const router = useRouter();
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [openSubscribePopup, setOpenSubscribePopup] = useAtom(subscribePopup);
  const currentURL = useGetCurrentUrl();
  const { user, logout } = useAuth();
  const [openPurchasePopup, setOpenPurchasePopup] = useAtom(purchasePopup);
  const [openPurchaseStartupPopup, setOpenPurchaseStartupPopup] =
    useAtom(purchaseStartupPopup);
  const [openRegisterPopup, setOpenRegisterPopup] = useAtom(registerPopup);
  const [currentAudio, setCurrentAudio] = useAtom(audioAtom);
  const [openPurchaseVCPopup, setOpenPurchaseVCPopup] =
    useAtom(purchaseVCPopup);
  const [openPurchaseAngelInvestorPopup, setOpenPurchaseAngelInvestorPopup] =
    useAtom(purchaseAngelInvestorPopup);

  useEffect(() => {
    const { query } = router;
    const paymentStatus = query.payment;
    const action = query.action;

    if (
      paymentStatus === "success-content" ||
      paymentStatus === "success-startup"
    ) {
      setShowPaymentSuccess(true);
    }

    if (paymentStatus === "success-content") {
      setSubscriptionType("content");
    }
    if (paymentStatus === "success-startup") {
      setSubscriptionType("startup");
    }

    if (
      localStorage.getItem("payment") &&
      localStorage.getItem("payment") === "Startup Registration"
    ) {
      if (localStorage.getItem("nextStep") !== "checkProfileCompleted") {
        // setShowPaymentSuccess(true);
        // router.push('/startupregistration')
      }
      // setSubscriptionType("startup");
      localStorage.removeItem("payment");
    }

    if (
      localStorage.getItem("payment") &&
      localStorage.getItem("payment") === "Content Access"
    ) {
      if (localStorage.getItem("nextStep") !== "checkProfileCompleted") {
        setShowPaymentSuccess(true);
      }
      setSubscriptionType("content");
      localStorage.removeItem("payment");
    }

    if (action == "openRegisterPopup") {
      localStorage.setItem("nextStep", "openRegisterPopup");
      location.href = "/";
    }

    if (localStorage.getItem("lcdt-podcast")) {
      setCurrentAudio(JSON.parse(localStorage.getItem("lcdt-podcast")));
    }
  }, []);

  const getNextStep = (_nextStep) => {
    switch (_nextStep) {
      case "purchasePopup":
        setOpenPurchasePopup(
          localStorage.getItem("purchasePopupType") || "content"
        );
        localStorage.removeItem("nextStep", "");
        localStorage.removeItem("purchasePopupType");
        break;
      case "purchaseStartupPopup":
        setOpenPurchaseStartupPopup(true);
        localStorage.removeItem("nextStep", "");
        break;
      case "openRegisterPopup":
        setOpenRegisterPopup(true);
        localStorage.removeItem("nextStep", "");
        break;
      case "accessMyAccessLink":
        router.push("/account/my-accesses");
        localStorage.removeItem("nextStep", "");
        break;
      case "goToUrl":
        const goToUrl = localStorage.getItem("nextStepUrl");
        if (goToUrl && user) {
          router.push(goToUrl);
        }
        localStorage.removeItem("nextStep", "");
        localStorage.removeItem("nextStepUrl", "");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Set a timeout to show the modal after 5 seconds
    const timeoutId = setTimeout(() => {
      if (localStorage.getItem("lc_already_open_newsletter") !== "false") {
        setOpenSubscribePopup(true);
        localStorage.setItem("lc_already_open_newsletter", "true");
      }
    }, 5000);

    if (localStorage.getItem("nextStep")) {
      if (router.pathname !== "/auth/google") {
        getNextStep(localStorage.getItem("nextStep"));
      }
    }

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array means this effect runs once on mount

  // Use seoData to override meta_title and description if available
  const effectiveMetaTitle = seoData?.meta_title || meta_title || title;
  const effectiveDescription = seoData?.meta_description || description;

  return (
    <>
      <Head>
        <title>{effectiveMetaTitle}</title>
        <meta name='title' content={effectiveMetaTitle} />
        <meta name='description' content={effectiveDescription} />
        <meta
          property='og:title'
          content={effectiveMetaTitle}
          key='title'
        />
        <meta property='og:description' content={effectiveDescription} />
        <meta name='twitter:title' content={effectiveMetaTitle} />
        <meta name='twitter:description' content={effectiveDescription} />
        <meta
          property='og:image'
          content={
            props.meta_image ||
            "https://cdn.lucidityinsights.com/thumbnaildefault.jpg"
          }
        />
        <link rel='icon' href='/favicon.png' />
      </Head>
      <PaymentSuccess
        open={showPaymentSuccess}
        setOpen={setShowPaymentSuccess}
        type={subscriptionType}
      />
      <Navbar user={user} logout={logout} />
      <div>{children}</div>
      <Register />
      <Login />
      <Subscribe />
      {currentAudio && <AudioPlayer />}
      {openPurchasePopup && user && <Purchase />}
      {openPurchaseStartupPopup && user && <PurchaseStartup />}
      {openPurchaseVCPopup && user && <PurchaseVC />}
      {openPurchaseAngelInvestorPopup && user && <PurchaseAngelInvestor />}
      <PurchaseAngelInvestor />
      {!currentURL.includes("/account") && <Footer />}
    </>
  );
};

export default Layout;
