const availablePrices = {
  AED: {
    "Startup Registration": "price_1NYgkAGlqmUCDtkjczVkNXml", // not used
    "Content Access": "price_1Ny9QrGlqmUCDtkj5ZIKlcZD",
    "VC Registration": "price_1PIhPSGlqmUCDtkjcRR6L7HH", // not used
    "Angel Investor Registration": "price_1QPEfBGlqmUCDtkjIg2qkfDe",
  },
  IDR: {
    "Startup Registration": "price_1NYbbqGlqmUCDtkjLR892I0S", // not used
    "Content Access": "price_1Ny9k4GlqmUCDtkjP2TPFQSg",
    "VC Registration": "price_1PIhPSGlqmUCDtkjcRR6L7HH", // not used
    "Angel Investor Registration": "price_1QPEfBGlqmUCDtkjIg2qkfDe",
  },
  USD: {
    "Startup Registration": "price_1Nl7zkGlqmUCDtkjwPFUcbSa", // not used
    "Content Access": "price_1Ny9RpGlqmUCDtkjsVUrLe3l",
    "VC Registration": "price_1PIhPSGlqmUCDtkjcRR6L7HH", // not used
    "Angel Investor Registration": "price_1QPEfBGlqmUCDtkjIg2qkfDe",
  },
  JOD: {
    "Startup Registration": "price_1Nl7zQGlqmUCDtkjlOPPDVED", // not used
    "Content Access": "price_1Ny9W0GlqmUCDtkj4q6Hp5Y0",
    "VC Registration": "price_1PIhPSGlqmUCDtkjcRR6L7HH", // not used
    "Angel Investor Registration": "price_1QPEfBGlqmUCDtkjIg2qkfDe",
  },
  SAR: {
    "Startup Registration": "price_1Nl7yaGlqmUCDtkjIyczrp2Q", // not used
    "Content Access": "price_1Ny9TUGlqmUCDtkjMVMIRlnH",
    "VC Registration": "price_1PIhPSGlqmUCDtkjcRR6L7HH", // not used
    "Angel Investor Registration": "price_1QPEfBGlqmUCDtkjIg2qkfDe",
  },
  EGP: {
    "Startup Registration": "price_1Nl7ywGlqmUCDtkjF2AWcNob", // not used
    "Content Access": "price_1Ny9UPGlqmUCDtkjFA7bwNcy",
    "VC Registration": "price_1PIhPSGlqmUCDtkjcRR6L7HH", // not used
    "Angel Investor Registration": "price_1QPEfBGlqmUCDtkjIg2qkfDe",
  },
};

// UAE
// Monthly:price_1Ny9QrGlqmUCDtkj5ZIKlcZD
// Yearly: price_1Ny9RUGlqmUCDtkjWKjswYRk

// USD: 
// Monthly: price_1Ny9RpGlqmUCDtkjsVUrLe3l
// Yearly: price_1Ny9SZGlqmUCDtkjtDAWacNF

// KSA:
// Monthly: price_1Ny9TUGlqmUCDtkjMVMIRlnH
// Yearly: price_1Ny9TqGlqmUCDtkj38V6znqg

// Egypt:
// Monthly: price_1Ny9UPGlqmUCDtkjFA7bwNcy
// Yearly: price_1Ny9UmGlqmUCDtkjurbE9Px7

// Jordan:
// Monthly:price_1Ny9W0GlqmUCDtkj4q6Hp5Y0
// Yearly: price_1Ny9WWGlqmUCDtkjEOh1Ul0c

export default availablePrices;
