import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { verifyCaptcha } from "@/services/api/Master";
import { subscribe } from "@/services/api/Newsletter";

const useSubscribe = (executeRecaptcha, setOpenSubscribePopup) => {
  const [loadingSubscribe, setLoadingSubscribe] = useState(false);

  const handleSubscribe = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        const email = event.target.email.value;
        const first_name = event.target.first_name.value;
        const last_name = event.target.last_name.value;
        const thisYear = new Date().getFullYear();

        setLoadingSubscribe(true);

        const token = await executeRecaptcha("form_subscribe");
        const response = await verifyCaptcha(token);

        if (response.data.score > 0.5) {
          const payload = {
            email,
            tags: ["Customer", String(thisYear), "Web"],
          };

          if (first_name) {
            payload.first_name = first_name;
          }

          if (last_name) {
            payload.last_name = last_name;
          }

          try {
            const res = await subscribe(payload);
            toast.success("You are successfully subscribed to our newsletter!");
            event.target.reset();
            setOpenSubscribePopup(false);
          } catch (error) {
            if (
              error.response?.data?.message === "User is already subscribed."
            ) {
              toast.error(error.response.data.message);
            }
          }
          setLoadingSubscribe(false);
        }

        // Call refresh using the passed router
        //   window.location.reload();
      } catch (error) {
        console.error(error);
      }
    },
    [executeRecaptcha, setOpenSubscribePopup]
  );

  return { handleSubscribe, loadingSubscribe };
};

export default useSubscribe;
