import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";

export async function checkSubscription(email) {
  return httpUtils.get(`${env.API_VERSION}/newsletter/check-subscription`, {
    params: { email },
  });
}

export async function subscribe(config) {
  return httpUtils.post(`${env.API_VERSION}/newsletter/subscribe`, config);
}

export async function getListCampaigns(config) {
  return httpUtils.get(`${env.API_VERSION}/newsletter/campaigns`, config);
}

export async function getCampaignContent(campaignId) {
  return httpUtils.get(
    `${env.API_VERSION}/newsletter/campaigns/${campaignId}/content`
  );
}

export async function getCampaignFolder(config) {
  return httpUtils.get(
    `${env.API_VERSION}/newsletter/campaign-folders`,
    config
  );
}
