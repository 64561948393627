import investorBenefits from "@/assets/json/investor-benefits.json";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import availablePricesDev from "@/hooks/availablePrices.dev";
import availablePricesMonthlyDev from "@/hooks/availablePrices.monthly.dev";
import availablePricesMonthlyProd from "@/hooks/availablePrices.monthly.prod";
import {
  ArrowFatLineUp,
  Check,
  X,
  CheckCircle,
  XCircle,
} from "@phosphor-icons/react";
import availablePricesProd from "@/hooks/availablePrices.prod";
import DummyLogo from "@/assets/images/lucidity-placeholder.png";

import {
  hidePurchasePopup,
  registerPopup,
  vcToClaimAtom,
  userAtom,
  purchaseAngelInvestorPopup,
  angelInvestorToClaimAtom,
} from "@/Atoms";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useAtom } from "jotai";
import CardForm from "../stripe/CardForm";
import useAuth from "@/hooks/auth";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import AppContext from "@/AppContext";
import Image from "next/legacy/image";
import allAccessIcon from "@/assets/images/all_access_icon.png";
import { ScrollArea } from "../ui/scroll-area";
import { getStripePrice } from "@/services/api/Stripe";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import Link from "next/link";
import ErrorMessage from "../ErrorMessage";
import {
  checkAngelInvestorExist,
  createVCByUser,
  updateVC,
} from "@/services/api/VentureCapital";
import toast from "react-hot-toast";
import { createAbandonedCart } from "@/services/api/User";
import VCCheckout from "./VCCheckout";
import VCOwnerModal from "./VCOwner";

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

const PurchaseAngelInvestor = () => {
  const [openPurchasePopup, setOpenPurchasePopup] = useAtom(purchaseAngelInvestorPopup);
  const [isHidePurchasePopup, setIsHidePurchasePopup] =
    useAtom(hidePurchasePopup);
  const [openRegisterPopup, setOpenRegisterPopup] = useAtom(registerPopup);
  const [angelInvestorToClaim, setAngelInvestorToClaim] = useAtom(angelInvestorToClaimAtom);

  const router = useRouter();
  const { type } = router.query;
  const context = useContext(AppContext);
  const { planSubscription, loading, refreshUserSubscription } = context.state;
  const [playing, setIsPlaying] = useState(false);
  const [state, setState] = useState(null);
  const [angelInvestorName, setAngelInvestorName] = useState("");
  const [angelInvestorWebsite, setAngelInvestorWebsite] = useState("");
  const [error, setError] = useState({});
  const [angelInvestorExist, setAngelInvestorExist] = useState(false);
  const [foundAngelInvestor, setFoundAngelInvestor] = useState(null);
  const [claimedAngelInvestor, setClaimedAngelInvestor] = useState(null);
  const [selectedStep, setSelectedStep] = useState(1);
  const { user } = useAuth();
  const [isOpenAngelInvestorOwner, setIsOpenAngelInvestorOwner] = useState(false);
  const [selectedAngelInvestorOwner, setSelectedAngelInvestorOwner] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userState, setUserState] = useAtom(userAtom);
  const options = {
    // passing the client secret obtained from the server
    clientSecret: process.env.STRIPE_SECRET_KEY,
  };

  const handleUserIpAddress = async () => {
    if (state) return;
    
    let response;
    try {
      response = await axios.get(
        "https://api.bigdatacloud.net/data/ip-geolocation",
        {
          params: {
            key: "bdc_e7257c8213814ec68c8bf6708ad4431d",
          },
        }
      );
    } catch (error) {
      console.error("Error fetching IP geolocation:", error);
    }

    const availablePrices =
      process.env.NEXT_PUBLIC_ENV !== "production"
        ? availablePricesDev
        : availablePricesProd;

    const availablePricesMonthly =
      process.env.NEXT_PUBLIC_ENV !== "production"
        ? availablePricesMonthlyDev
        : availablePricesMonthlyProd;

    let priceData = {};
    let priceDataMonthly = {};
    let currencyCode = "USD";
    let priceIds = availablePrices["USD"];
    let priceIdsMonthly = availablePricesMonthly["USD"];
    const codes = ["AED", "IDR", "JOD", "EGP", "SAR"];

    if (response?.data?.country) {
      const currency = response.data.country.currency;
      if (currency?.code && codes.includes(currency.code)) {
        currencyCode = currency.code;
        priceIds = availablePrices[currencyCode];
        priceIdsMonthly = availablePricesMonthly[currencyCode];
      }
    }

    try {
      for (const pKey in priceIds) {
        const [stripePriceDataResponse, stripePriceDataMonthlyResponse] = await Promise.all([
          getStripePrice(priceIds[pKey]),
          getStripePrice(priceIdsMonthly[pKey])
        ]);

        priceData[pKey] = stripePriceDataResponse.data;
        priceDataMonthly[pKey] = stripePriceDataMonthlyResponse.data;
      }

      setState({
        userLocation: response?.data || null,
        currencyCode,
        priceData,
        priceDataMonthly,
        priceIds,
        priceIdsMonthly,
        ip: response?.data?.ip,
      });
    } catch (error) {
      console.error("Error fetching stripe prices:", error);
    }
  };

  useEffect(() => {
    if (openPurchasePopup) {
      handleUserIpAddress();
    }
  }, [openPurchasePopup]);

  useEffect(() => {
    if (angelInvestorToClaim) {
      setAngelInvestorName(angelInvestorToClaim.name);
      setAngelInvestorWebsite(angelInvestorToClaim.website);
      onClaimAngelInvestor(angelInvestorToClaim);
    }
  }, [angelInvestorToClaim]);

  useEffect(() => {
    if (openPurchasePopup) {
      handleAbandonedCart();
    }
  }, [openPurchasePopup]);

  const handleAbandonedCart = async () => {
    const res = await createAbandonedCart({
      user_id: userState?.id,
      email: userState?.email,
      action: "Click Register Angel Investor Button",
      location: router.pathname,
      label: "Angel Investor Registration",
    });
  };

  const handleCheckAngelInvestorInfo = async () => {
    setError({
      angelInvestorName: !angelInvestorName ? "Angel Investor name is required!" : "",
    });

    if (!angelInvestorName) return;

    const checkAngelInvestorResponse = await checkAngelInvestorExist({
      name: angelInvestorName,
    });
    
    setAngelInvestorExist(checkAngelInvestorResponse.data.exists);
    
    if (checkAngelInvestorResponse.data && checkAngelInvestorResponse.data.exists) {
      setFoundAngelInvestor(checkAngelInvestorResponse.data.vc);
      setSelectedStep(1);
    } else {
      if (planSubscription.is_angel_investor_plan) {
        handleRegisterAngelInvestor();
      } else {
        onUpgradeAngelInvestor();
      }
      setFoundAngelInvestor(null);
    }
  };

  const handleCancel = () => {
    setOpenPurchasePopup(false);
    setError({});
    setAngelInvestorName("");
    setAngelInvestorWebsite("");
    setSelectedStep(null);
    setAngelInvestorExist(false);
    setFoundAngelInvestor(null);
  };

  const handleReset = () => {
    setError({});
    setAngelInvestorName("");
    setAngelInvestorWebsite("");
    setSelectedStep(null);
    setAngelInvestorExist(false);
    setFoundAngelInvestor(null);
    setSelectedStep(1);
  };

  const onSelectOwner = (value) => {
    setIsOpenAngelInvestorOwner(true);
    setSelectedAngelInvestorOwner(value);
  };

  const handleRegisterAngelInvestor = async () => {
    const payload = {
      name: angelInvestorName,
      website: angelInvestorWebsite,
      creator_id: user.id,
      owner_id: user.id,
      owner_email: user.email,
      owning_type: "registered",
      investor_type: "angel",
      is_angel: true
    };
    
    setIsLoadingSubmit(true);
    try {
      const response = await createVCByUser(payload);
      toast.success("Your Angel Investor profile has been successfully created");
      location.href = `/angel-investors/${response.data.data.slug}-${response.data.data.id}`;
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoadingSubmit(false);
  };

  const onClaimAngelInvestor = async (angelInvestor) => {
    if (planSubscription.is_angel_investor_plan) {
      handleClaimAngelInvestor(angelInvestor.id);
    } else {
      setClaimedAngelInvestor(angelInvestor);
      onUpgradeAngelInvestor();
    }
    setFoundAngelInvestor(null);
  };

  const handleClaimAngelInvestor = async (angelInvestorId) => {
    const payload = {
      name: angelInvestorName,
      website: angelInvestorWebsite,
      owner_id: user.id,
      claimed: 1,
      owner_email: user.email,
      is_angel: true
    };

    setIsLoadingSubmit(true);
    try {
      const response = await updateVC(angelInvestorId, payload);
      setAngelInvestorToClaim(null);
      setClaimedAngelInvestor(null);
      toast.success("Your Angel Investor has been successfully updated");
      location.href = `/angel-investors/${response.data.data.slug}-${response.data.data.id}`;
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoadingSubmit(false);
  };

  const onUpgradeAngelInvestor = (type) => {
    // if (!user) {
    //   return setOpenLoginPopup(true);
    // }
    setIsOpen(true);
  };

  return (
    <Dialog open={!!openPurchasePopup} onOpenChange={setOpenPurchasePopup}>
      <DialogContent
        isHidePurchasePopup={isHidePurchasePopup}
        className={`max-w-6xl p-0 bg-white text-background ${
          isHidePurchasePopup ? "hidden" : ""
        }`}
        hideCloseButton
      >
        <ScrollArea className='max-h-[100dvh]'>
          <div className='flex flex-col lg:flex-row'>
            <div className='relative lg:flex-1 lg:m-2.5 lg:mr-0 overflow-hidden bg-gray-100 lg:rounded-md shadow-inner'>
              <button
                onClick={() => setOpenPurchasePopup(false)}
                type='button'
                className='absolute inline-flex md:hidden items-center justify-center rounded-full w-9 h-9 bg-white/[0.15] right-3 top-3'
              >
                <X className='text-xl text-white' />
              </button>
              <div className='p-8 pt-5 text-center text-white lg:pb-10 bg-gradient-to-tr from-orange-1 to-red-1'>
                <span className='inline-flex items-center justify-center mb-3 text-3xl rounded-full w-14 h-14 bg-white/20'>
                  <ArrowFatLineUp />
                </span>
                <p className='text-3xl font-semibold tracking-tight lg:text-5xl font-display'>
                  Upgrade to the Angel Investor Plan!
                </p>
                <p className='mt-3 opacity-80'>
                  To edit and publish your Angel Investor profile, you&apos;ll need to
                  purchase our Angel Investor Plan for{" "}
                  <span className='font-semibold'>
                    {state?.currencyCode}{" "}
                    {(
                      state?.priceData["Angel Investor Registration"]?.unit_amount / 100
                    ).toLocaleString("en", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    /year
                  </span>
                </p>
              </div>
              <div className='justify-center hidden lg:flex lg:-mt-5'>
                <div className='flex items-center p-1 pr-4 bg-white rounded-full shadow-lg gap-x-2'>
                  <div className='overflow-hidden border rounded-full w-9 h-9 border-background/20'>
                    <div className='relative w-9 h-9 aspect-square'>
                      <Image
                        src={allAccessIcon.src}
                        alt=''
                        layout='fill'
                        objectFit='cover'
                      />
                    </div>
                  </div>
                  <div>
                    <p className='text-xs font-medium leading-none'>
                      Angel Investor
                    </p>
                  </div>
                </div>
              </div>
              <div className='grid gap-2 p-5 text-sm lg:grid-cols-2 lg:text-xs lg:p-8 text-background'>
                {investorBenefits.map((item, index) => (
                  <div
                    className='inline-flex items-start space-x-2.5'
                    key={index}
                  >
                    <span className='inline-flex items-center justify-center flex-shrink-0 w-[18px] h-[18px] text-white rounded-full bg-gradient-to-tr from-green-300 to-green-500'>
                      <Check weight='bold' className='text-xs' />
                    </span>
                    <span className='translate-y-px'>{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className='p-5 lg:p-7 lg:w-2/5'>
              {selectedStep === 1 && (
                <div>
                  <div className='grid grid-cols-12 gap-4 mt-10'>
                    <div className='flex flex-col col-span-12 gap-1.5'>
                      <Label className='text-background'>Angel Investor Name</Label>
                      <Input
                        className='bg-white ring-offset-white border-background/20'
                        required
                        value={angelInvestorName}
                        onInput={(e) => setAngelInvestorName(e.target.value)}
                        disabled={foundAngelInvestor}
                      />
                      {error && error.vcName && (
                        <ErrorMessage message={error.vcName} />
                      )}
                    </div>
                    
                    <div className='flex flex-col col-span-12 gap-1.5'>
                      <Label className='text-background'>Website (Optional)</Label>
                      <Input
                        className='bg-white ring-offset-white border-background/20'
                        value={angelInvestorWebsite}
                        onInput={(e) => setAngelInvestorWebsite(e.target.value)}
                        disabled={foundAngelInvestor}
                      />
                    </div>

                    {selectedStep == 1 && foundAngelInvestor && (
                      <div className='col-span-12 mt-4'>
                        <p className='font-medium'>
                          We found the Angel Investor, is it yours?
                        </p>

                        <div className='divide-y divide-black/10 max-h-[30vh] 2xl:max-h-[40vh] overflow-y-auto -my-2 pt-4'>
                          <div className='gap-4 py-2'>
                            <div className='flex items-start space-x-3'>
                              <div className='relative w-10 overflow-hidden border rounded-lg shadow-sm border-background/10 aspect-square'>
                                <Image
                                  src={foundAngelInvestor.logo_url || DummyLogo}
                                  layout={"fill"}
                                  objectFit={"cover"}
                                  alt='Image'
                                />
                              </div>
                              <div>
                                <div className='relative text-sm font-medium text-background'>
                                  <span className='mr-6'>{foundAngelInvestor.name}</span>
                                  {foundAngelInvestor.owner_id && (
                                    <span className='w-full px-2 py-1 text-xs font-semibold text-center uppercase border rounded bg-gradient-to-r from-yellow-500/20 to-orange-500/20 border-white/30'>
                                      Claimed
                                    </span>
                                  )}
                                </div>
                                <a
                                  href={foundAngelInvestor.website}
                                  className='block mt-0.5 text-xs font-medium hover:underline opacity-50'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  {foundAngelInvestor.website}
                                </a>
                                <div className='flex mt-2 gap-x-4'>
                                  {foundAngelInvestor.is_published && (
                                    <a
                                      target='_blank'
                                      href={`/angel-investors/${foundAngelInvestor.slug}-${foundAngelInvestor.id}`}
                                      rel='noopener noreferrer'
                                      className='px-2 py-1 text-xs font-medium bg-gray-100 border border-gray-200 rounded-md'
                                    >
                                      Review
                                    </a>
                                  )}
                                  {!foundAngelInvestor.owner_id && (
                                    <Button
                                      className='text-xs bg-transparent text-background'
                                      size='xs'
                                      onClick={() => onClaimAngelInvestor(foundAngelInvestor)}
                                      disabled={isLoadingSubmit}
                                    >
                                      Claim
                                    </Button>
                                  )}
                                  {foundAngelInvestor.owner_id && (
                                    <Button
                                      className='text-xs bg-transparent text-background'
                                      size='xs'
                                      onClick={() => onSelectOwner(foundAngelInvestor)}
                                    >
                                      Who Claimed This?
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {selectedStep == 1 && !angelInvestorExist ? (
                    <div className='col-span-12'>
                      <div className='flex gap-x-1.5 mt-4'>
                        <Button
                          variant='outline'
                          className='bg-white border-background/20 hover:bg-background/5'
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant='secondary'
                          className='flex-1'
                          onClick={handleCheckAngelInvestorInfo}
                          disabled={isLoadingSubmit}
                        >
                          Register Angel Investor
                        </Button>
                      </div>
                      <p className='mt-3 text-xs opacity-50'>
                        We need to check whether or not your Angel Investor already exists
                        on Lucidity Insights&apos; platform. If it does, you
                        have the option to immediately claim your Angel Investor.
                      </p>
                    </div>
                  ) : (
                    angelInvestorExist && (
                      <div className='flex justify-end mt-4 gap-x-4'>
                        <Button
                          variant='outline'
                          className='bg-white border-background/20 hover:bg-background/5'
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                        <Button
                          variant='outline'
                          className='bg-white border-background/20 hover:bg-background/5'
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </div>
                    )
                  )}
                </div>
              )}

              {selectedStep == 2 && (
                <div className='w-full p-3 my-6 rounded bg-slate-200'>
                  {claimedAngelInvestor && (
                    <p className='mb-3 text-sm font-semibold'>
                      You are about to claim this Angel Investor
                    </p>
                  )}
                  <div className='flex justify-between gap-x-4'>
                    <div className='w-1/2'>
                      <p className='text-xs'>Angel Investor Name</p>
                      <p className='text-sm'>
                        {claimedAngelInvestor ? claimedAngelInvestor.name : angelInvestorName}
                      </p>
                    </div>
                    <div className='w-1/2'>
                      <p className='text-xs'>Website (Optional)</p>
                      <p className='text-sm'>
                        {claimedAngelInvestor ? claimedAngelInvestor.website : angelInvestorWebsite}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {state && selectedStep == 2 ? (
                <Elements stripe={stripePromise} options={options}>
                  <CardForm
                    theme='light'
                    planType={"Angel Investor Registration"}
                    refreshUserSubscription={refreshUserSubscription}
                    planSubscription={planSubscription}
                    {...{
                      userLocation: state?.userLocation,
                      priceData: state?.priceData,
                      priceDataMonthly: state?.priceDataMonthly,
                      priceIds: state?.priceIds,
                      priceIdsMonthly: state?.priceIdsMonthly,
                      currencyCode: state?.currencyCode,
                      ip: state?.ip,
                    }}
                    nextAction={
                      claimedAngelInvestor
                        ? () => handleClaimAngelInvestor(claimedAngelInvestor.id)
                        : () => handleRegisterAngelInvestor()
                    }
                  />
                </Elements>
              ) : (
                !state &&
                selectedStep == 2 && (
                  <div className='flex items-center justify-center'>
                    <p className='text-slate-500 animate-pulse'>
                      Loading Payment
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </ScrollArea>
      </DialogContent>
      {isOpenAngelInvestorOwner && (
        <VCOwnerModal
          isOpen={isOpenAngelInvestorOwner}
          setIsOpen={setIsOpenAngelInvestorOwner}
          angelInvestor={selectedAngelInvestorOwner}
        />
      )}
      <VCCheckout
        {...{
          userLocation: state?.userLocation,
          priceData: state?.priceData,
          priceDataMonthly: state?.priceDataMonthly,
          priceIds: state?.priceIds,
          priceIdsMonthly: state?.priceIdsMonthly,
          currencyCode: state?.currencyCode,
          ip: state?.ip,
          isOpen,
          setIsOpen,
          createVC: true,
          vcType: "angel",
          createVCPayload: {
            name: angelInvestorName,
            website: angelInvestorWebsite,
            proposed_by: user?.id,
            proposed_owner_email: user?.email,
            owning_type: "registered",
            investor_type: "angel",
            is_angel: true,
          },
        }}
      />
    </Dialog>
  );
};

export default PurchaseAngelInvestor;
